<template>
  <v-card
    class="balance-summary"
    v-if="account"
  >
    <v-card-title class="align-start">{{$t('t.Balance')}}
      <v-spacer />
      <v-btn-toggle
        v-model="mode"
        mandatory
      >
        <v-btn
          icon
          small
          value="current-due"
        >
          <v-icon small>{{$icon('i.ChartHistogram')}}</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          value="net"
        >
          <v-icon small>{{$icon('i.ChartTimelineVariant')}}</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-subtitle v-if="mode === 'current-due'">{{`${$t('t.Current')} / ${$t('t.Due')}`}}</v-card-subtitle>
    <v-card-subtitle v-else>{{`${$t('t.Net')} / ${$t('t.Risk')}`}}</v-card-subtitle>

    <v-card-text>
      <component
        :is="mode"
        :account="account"
      />
      <v-list>
        <grid
          class="flex-grow-1"
          item-min-width="125px"
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
          >
            <v-list-item-title>{{formatCurrency(item.amount, account.currency)}}</v-list-item-title>
            <v-list-item-subtitle>{{item.title}}</v-list-item-subtitle>
          </v-list-item>
        </grid>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    Grid: () => import('@/components/grid.vue'),
    CurrentDue: () => import('./current-due-timeline'),
    Net: () => import('./balance-net-risk-timeline'),
    Trend: () => import('../kpi/trend')
  },
  computed: {
    items () {
      const array = [
        this.getItem('current', this.$t('t.Current')),
        this.getItem('due', this.$t('t.Due')),
        this.getItem('unappliedCash', this.$t('t.UnappliedCash')),
        this.getItem('fees', this.$t('t.Fees')),
        this.getItem('workInProgress', this.$t('t.WorkInProgress')),
        this.getItem('net', this.$t('t.Net')),
        this.getItem('risk', this.$t('t.Risk')),
        this.getItem('dueNet', this.$t('t.NetPastDueBalance'))
      ]

      const groups = []

      for (let i = 0; i < array.length; i++) {
        const item = array[i]
        const group = groups.find(g => g.amount === item.amount)

        if (group) {
          group.title += ' / ' + item.title
        } else {
          groups.push(item)
        }
      }

      return groups
    },
    balance () {
      return this.current + this.due
    },
    current () {
      return this.getBalance('current')
    },
    due () {
      return this.getBalance('due')
    },
    dueNet () {
      return this.getBalance('dueNet')
    },
    fees () {
      return this.getBalance('fees')
    },
    net () {
      return this.getBalance('net')
    },
    risk () {
      return this.getBalance('risk')
    },
    unappliedCash () {
      return this.getBalance('unappliedCash')
    },
    workInProgress () {
      return this.getBalance('workInProgress')
    }
  },
  data () {
    return {
      mode: 'current-due'
    }
  },
  methods: {
    getItem (key, title) {
      const index = (this.account?.days?.length ?? 0) - 1
      const amount = this.account?.[key]?.data?.[index]

      return { amount, title }
    }
  },
  props: {
    account: Object
  }
}
</script>

<style lang="stylus" scoped>
.v-list
  display flex
  flex-direction row
  flex-wrap wrap

.v-list-item
  display flex
  flex-direction column
  min-height auto
  flex-basis auto
</style>
